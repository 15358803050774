<template>
  <div class="main">
    <banner-swiper class="other">
      <swiper-slide>
        <div class="banner-cover" />
        <div class="banner-desc other">
          <div class="icon" />
          <div class="title">费索斯</div>
          <div class="text">年轻与老练并存 活力与沉稳并济</div>
        </div>
      </swiper-slide>
      <div slot="after" class="english-run">FEYSOS</div>
    </banner-swiper>
    <div class="amap wrap">
      <div v-scroll-toggle="['enter']">
        <el-amap ref="map" :resize-enable="true" :animate-enable="false" :jog-enable="false" :zoom="map.zoom" :center="map.center" :events="events" vid="map" class="box">
          <el-amap-marker :position="marker.position" vid="marker">
            <div class="mark" />
          </el-amap-marker>
        </el-amap>
        <div class="back" @click="back" />
        <div class="desc">
          <div>
            <div class="label">地址：</div>
            <div class="value">浙江省宁波高新区翔云北路 199 号 1 号楼 13-3</div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Vue from 'vue'
import VueAMap from 'vue-amap'
import BannerSwiper from '@/components/bannerSwiper'

import scrollToggle from '@/directives/scrollToggle'

Vue.use(VueAMap)
VueAMap.initAMapApiLoader({
  key: '2bbbfd2a980504e30a9ca91e092c007d'
})

let AMap = window.AMap
const position = [121.625439, 29.895705]

export default {
  name: 'About',
  components: {
    BannerSwiper
  },
  directives: {
    scrollToggle
  },
  data() {
    const self = this
    return {
      map: {
        zoom: 18,
        center: position
      },
      marker: {
        position: position
      },
      events: {
        init(map) {
          AMap = AMap || window.AMap
          self.init(map)
          self.resize(map, map.getSize())
        },
        resize(e) {
          self.resize(self.$refs.map.$amap, e.newsize)
        }
      }
    }
  },
  methods: {
    init(map) {
      map.setMapStyle('amap://styles/d1f0adf496e38322d6ed32bd23c2c810')
    },
    back() {
      const map = this.$refs.map.$amap
      this.resize(map, map.getSize())
    },
    resize(map, data) {
      const positionCurrent = map.lngLatToContainer(position)
      let positionAims = null
      if (data.width < 850) {
        positionAims = {
          x: data.width / 2,
          y: data.height / 3
        }
      } else {
        positionAims = {
          x: data.width / 4 * 3,
          y: data.height / 2
        }
      }
      map.panBy(positionAims.x - positionCurrent.x, positionAims.y - positionCurrent.y)
    }
  }
}
</script>

<style lang="scss" scoped>
.banner-container{
  .banner-cover{
    background-image: url('../../assets/banner_about.jpg');
  }
}
.amap{
  padding: 80px 0;
  >div{
    position: relative;
    display: flex;
    height: 630px;
    align-items: center;
    visibility: hidden;
    &.enter{
      visibility: visible;
      animation: fadeIn 1s backwards;
    }
  }
  .box{
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 0;
    background-color: $themeBarColor;
  }
  .mark{
    position: relative;
    width: 14px;
    height: 14px;
    margin-top: 7px;
    margin-left: -7px;
    border-radius: 50%;
    background-color: #ff0000;
    &:before, &:after{
      position: absolute;
      top: -100%;
      left: -100%;
      right: -100%;
      bottom: -100%;
      box-sizing: border-box;
      border-radius: 50%;
      animation: amapMark 1s ease-out infinite;
      content: '';
    }
    &:before{
      background-color: rgba(#ff0000, .6);
    }
    &:after{
      border: 1px solid #ff0000;
    }
    @keyframes amapMark{
      from{
        transform: scale(.3);
        opacity: 1;
      }
      to{
        transform: scale(1);
        opacity: 0;
      }
    }
  }
  .back{
    position: absolute;
    top: 10px;
    right: 10px;
    z-index: 1;
    display: flex;
    width: 26px;
    height: 26px;
    box-sizing: border-box;
    border-radius: 50%;
    border: 2px solid #7b1b15;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    &:before{
      display: block;
      width: 10px;
      height: 10px;
      border-radius: 50%;
      background-color: #7b1b15;
      content: '';
    }
  }
  .desc{
    position: relative;
    z-index: 1;
    display: flex;
    width: 350px;
    margin-left: 85px;
    box-sizing: border-box;
    padding: 50px 30px;
    background-color: #7b1b15;
    line-height: 1.5em;
    font-size: 16px;
    color: $themeColorActive;
    >div{
      display: flex;
    }
    .label{
      flex-shrink: 0;
      flex-grow: 0;
    }
    .value{
      flex-shrink: 1;
      flex-grow: 1;
      text-align: justify;
    }
  }
  @media screen and (max-width: 850px) {
    >div{
      justify-content: center;
      align-items: flex-end;
    }
    .desc{
      margin-left: 0;
      margin-bottom: 30px;
    }
  }
  @media screen and (max-width: 800px) {
    >div{
      width: 80%;
      height: 400px;
      margin: 0 auto;
    }
    .desc{
      width: auto;
      margin-left: 10px;
      margin-right: 10px;
      margin-bottom: 10px;
      padding: 15px 20px;
    }
  }
}
</style>
